@import "../../../variables/variables.scss";

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  .checkbox_forgot-container {
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .login-form-forgot {
      color: $text-grey;
    }
  }

  .marginAuto {
    margin: auto;
  }

  .social-buttons {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
    display: flex;
    width: 90px;
    margin: auto;
    align-items: center;
    justify-content: space-between;

    .social-button {
      width: 35px;
      background-color: transparent;

      &:hover,
      &:active {
        background-color: transparent;
      }
    }
  }
}
