@import "../../variables/variables.scss";

.container {
  background-color: $background-color;
  display: flex;
  height: 100vh;
  justify-content: space-between;
  align-items: center;

  .image-container {
    width: calc(100% - 10px);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .cover-image {
      max-width: 100%;
      height: auto;
      width: 70%;
    }
  }

  .form-container {
    width: calc(100% - 10px);
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
      margin-bottom: 2rem;
    }

    .btn-grey {
      background-color: $btn-grey;

      &:hover {
        background-color: rgb(54 54 62);
        color: white;
      }
    }
  }
}
