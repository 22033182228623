@import "../../variables/variables.scss";

.assignmenStatusDetails-container {
  margin-top: -60px;
  width: 100%;

  .assignmentStatus-card:last-child {
    margin-bottom: 0;
  }
  .assignmentStatus-card {
    background-color: $nav-backgound-color;
    border: 1px solid $nav-backgound-color;
    border-radius: 16px;
    margin-bottom: 0.8rem;
    width: 100%;

    .ant-card-body {
      padding: 0.8rem 1.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      color: $white-color;

      .border {
        width: 45px;
        height: 1px;
        position: absolute;
        top: -1px;
      }

      .light-red {
        background-color: $orange;
      }

      .light-blue {
        background-color: $light-blue;
      }

      .red {
        background-color: $red;
      }

      .green {
        background-color: $green;
      }

      .name {
        font-weight: 600;
        font-size: 12px;
        color: $text-grey;
        margin: 0;
      }

      .count {
        font-weight: 600;
      }

      &::before,
      &::after {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 930px) {
  .assignmenStatusDetails-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 450px) {
  .assignmenStatusDetails-container {
    margin-top: 200px;
    width: 280px;
  }
}
