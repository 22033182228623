@import "../../../variables/variables.scss";

.register-form {
  display: flex;
  flex-direction: column;
  align-items: center;

  .profile-section {
    display: flex;
    justify-content: center;

    .avatar-container {
      position: relative;
      cursor: pointer;

      .profile-edit-btn {
        height: 15px;
        width: 15px;
        position: absolute;
        bottom: 4px;
        left: 45.5px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $light-blue;
        border-radius: 2px;
      }

      .anticon-spin::before,
      .anticon-spin {
        position: absolute;
        top: 25px;
        left: 24px;
      }

      .avatar-loading {
        filter: blur(2px);
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 0.5rem;

    .camera-btn {
      width: 10px;
      position: relative;
      height: 10px;
      padding: 10px 12px !important;
      bottom: 0;
      left: 0;
      background-color: $background-color;
      border: 2px solid $text-grey;

      .icon {
        transform: scale(1);
        color: $text-grey;
      }
    }
  }

  .verification {
    .verification-email {
      color: $white-color;
      text-align: center;
    }

    .content {
      color: $red;
      text-align: center;
    }
  }
}
.password-content {
  text-align: center;
}

.anticon-upload {
  color: $text-grey;
}
