@import "../../variables/variables.scss";

.hub-container {
  display: flex;
  justify-content: space-between;
  padding: 1rem;

  .or-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .ant-divider-horizontal.ant-divider-with-text::after,
    .ant-divider-horizontal.ant-divider-with-text::before {
      position: relative;
      width: 40%;
      border-block-start: 2px solid $form-inout-color !important;
      border-block-end: 0;
      transform: translateY(50%);
      content: "";
    }

    .v-divider {
      border-inline-start: none;
      height: 70vh;
      width: 1.5px;
    }

    .h-divider {
      display: none;
    }
  }

  .search-section-container,
  .hub-form-container {
    padding: 1rem;
    width: calc(100% - 42px);
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      text-align: center;
    }
  }

  .search-section-container {
    .searchbar-container {
      margin-bottom: 1.5rem;
      .search-bar {
        width: 400px;

        .ant-input-group-addon {
          height: 45.6px;

          .ant-input-search-button {
            height: 100%;
            border: $form-input-border;
            color: $text-grey;
            background-color: $background-color;
            border-top-right-radius: 16px;
            border-bottom-right-radius: 16px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 930px) {
  .hub-container {
    .search-section-container {
      .searchbar-container {
        .search-bar {
          width: 350px;
        }
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .hub-container {
    flex-direction: column;
    align-items: center;

    .or-container {
      width: 100%;
      margin: auto;
      h1 {
        display: none;
      }
      .h-divider {
        display: flex;
        justify-content: center;
        border-block-start: none;
        border-block-start: 0;
        height: 2.5px;
        .ant-divider-inner-text {
          color: $white-color;
          font-size: 25px;
          font-family: $font-family;
        }
      }

      // .divider-color {
      //   background: transparent;
      // }

      .v-divider {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .hub-container {
    .search-section-container {
      .searchbar-container {
        .search-bar {
          width: 300px;
        }
      }
    }
  }
}
