@import "../../variables/variables.scss";

.support-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  .page-heading {
    color: $white-color;
    margin-bottom: 2rem;
  }
  .ant-upload-select {
    width: 100%;
    margin-bottom: 10px;
    .upload-btn {
      background-color: transparent;
      border: $form-input-border;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 16px;
      .anticon-upload {
        margin-bottom: 5px;
        svg {
          height: 20px;
          width: 20px;
        }
      }
      .upload,
      .upload-condition {
        color: $text-grey;
      }

      .upload {
        margin-inline-start: 0;
      }

      .upload-condition {
        font-size: 12px;
      }
    }
  }
  .ant-upload-wrapper .ant-upload-list::before,
  .ant-upload-wrapper .ant-upload-list::after {
    display: none;
  }
  .ant-upload-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 5px;
    .ant-upload-list-item-container {
      width: 195px;

      .ant-upload-list-item {
        border: $form-input-border;

        .anticon-delete {
          color: $white-color !important;
        }
      }
    }
  }
}

@media screen and (max-width: 930px) {
  .support-page {
    .ant-upload-list {
      .ant-upload-list-item-container {
        width: 170px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .support-page {
    .ant-upload-list {
      .ant-upload-list-item-container {
        width: 145px;
      }
    }
  }
}
