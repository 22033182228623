@import "../../variables/variables.scss";

.assignmenDetailsPage-container {
  display: flex;
  justify-content: center;

  .assignmentDetails-card {
    background-color: $background-color;
    border: $form-input-border;
    border-radius: 16px;
    width: 100%;
    max-width: 400px;

    .ant-card-head {
      .ant-card-head-wrapper {
        .ant-card-extra {
          .edit-btn-small {
            background-color: $background-color;
            border: $form-input-border;
            width: 45px;
            justify-content: space-between;
            font-weight: 400;
            font-size: 10px;
            padding: 3px 7px !important;
            border-radius: 2px;
          }
        }
      }
    }

    .ant-card-body {
      padding: 0rem 2rem 1rem 2rem;
      display: flex;
      flex-direction: column;
      color: $white-color;

      .title-container {
        display: flex;
        align-items: center;
        // justify-content: center;

        .emoji {
          font-size: 2.5rem;
          margin-right: 10px;
        }

        .assignment_name {
          font-size: 2rem;
          font-weight: 700;
          text-transform: capitalize;
          text-align: center;
        }
      }

      .points {
        text-align: center;
        font-weight: 700;
        font-size: 12px;
      }

      .ant-progress .ant-progress-inner {
        background-color: $nav-backgound-color;
        .ant-progress-bg {
          background-color: $light-blue;
          height: 3px !important;
        }
      }

      .itemContainer {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        .item-title {
          font-size: 15px;
          margin-bottom: 10px;
          font-weight: 700;
        }

        .item-content {
          font-size: 11px;
        }

        .assignedCrew-cards {
          .assignedCrew-card {
            background-color: $background-color;
            border: $form-input-border;
            border-radius: 16px;
            margin-bottom: 0.8rem;
            width: 100%;

            .ant-card-body {
              padding: 0.5rem 1rem;
              display: flex;
              flex-direction: row;
              align-items: center;
              color: $white-color;

              .name-level {
                display: flex;
                flex-direction: column;
                margin-left: 10px;

                .crew-name {
                  text-transform: capitalize;
                  font-size: 14px;
                  font-weight: 600;
                }

                .crew-level {
                  font-size: 11px;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .assignmenDetailsPage-container {
    .assignmentDetails-card {
      .ant-card-body {
        .title-container {
          .assignment_name {
            font-size: 1.75rem;
          }
        }
      }
    }
  }
}
