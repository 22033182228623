@import "./variables/variables.scss";

.ant-card,
.ant-steps,
.ant-modal,
.ant-message-notice-content,
.steps,
.ant-steps-icon,
.ant-btn,
.ant-card,
.ant-card-body,
.ant-form,
.ant-input,
.ant-form-item .ant-form-item-label > label,
.ant-col,
.ant-radio-wrapper,
.ant-radio-button-wrapper,
.ant-form-item-explain-error,
.ant-checkbox-wrapper,
.ant-divider,
.ant-upload,
.ant-slider,
.ant-progress,
.ant-radio-group,
.ant-upload-list-item-name,
.ant-dropdown,
.ant-descriptions,
.ant-drawer,
.ant-empty,
.ant-input-number .ant-input-number-input,
.ant-popover,
.ant-select-single .ant-select-selector {
  font-family: $font-family;
}

.ant-avatar {
  display: block;
}

.full-width {
  width: 100%;
}

.marginAuto {
  margin: auto;
}

p {
  color: $text-grey;
}

.divider-background {
  background-color: $text-grey;
}
.color-white {
  color: $white-color;
}

.divider-color {
  background-color: $divider-color;
}

.ant-divider-vertical {
  border-inline-start: none;
}
//buttons

.btn-blue {
  background-color: $light-blue;

  &:hover,
  &:active {
    background-color: rgb(79, 181, 240) !important;
    color: $white-color !important;
  }
}

.btn-grey {
  background-color: $btn-grey;

  &:hover {
    background-color: rgb(54 54 62);
    color: $white-color !important;
  }
}

.btn-black {
  background-color: transparent;
  color: $white-color !important;
  border: $form-input-border;
  &:hover {
    color: $white-color !important;
    border: $form-input-border !important;
  }
}

.btn-requested {
  background-color: $background-color !important;
  color: $white-color !important;
  border: $form-input-border !important;
}

.btn-delete {
  background-color: $background-color !important;
  color: $red !important;
  border: $form-input-border !important;
}

.btn-primary-disabled {
  cursor: not-allowed;
  &:hover {
    background-color: $light-blue !important;
  }
}

.btn-delete-disabled {
  cursor: not-allowed;
  &:hover {
    background-color: $background-color !important;
  }
}

.btn-disbaled {
  cursor: not-allowed;
}

.ant-btn:not(:disabled):focus-visible {
  outline: none;
}

.ant-btn-default:disabled {
  color: white;
  background-color: $nav-backgound-color;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):active {
  color: $white-color;
}

.ant-input,
.ant-input-password {
  padding: 11px 16px;
}

.ant-input-affix-wrapper .anticon.ant-input-password-icon {
  color: $text-grey;
  &:hover {
    color: $text-grey;
  }
}

.ant-picker-input {
  input::placeholder {
    color: $text-grey !important;
  }
}

.ant-form-item .ant-form-item-label > label {
  color: $text-grey;
}

.ant-checkbox-wrapper {
  color: $text-grey;
}

.ant-checkbox-inner {
  background-color: $background-color !important;
  border: $form-input-border !important;
}

.ant-form-item-explain-error {
  color: #e07e7f !important;
}

.ant-input-status-error,
.ant-input-affix-wrapper-status-error {
  border-color: #e07e7f !important;
}

.ant-form {
  width: 400px;
}

.ant-select-selector {
  background-color: $background-color !important;
  padding: 8px 16px !important;
  border: $form-input-border !important;
  border-radius: 16px !important;
  font-family: $font-family;
  height: 46px !important;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: 0 !important;
}

.ant-select-selection-placeholder,
.ant-select .ant-select-arrow,
.ant-select-selection-item,
.ant-select-item-option {
  color: $text-grey !important;
}

.ant-select-item-option:hover {
  color: $white-color !important;
}
.ant-select-item-option-selected {
  color: $white-color !important;
}

.anticon-close-circle {
  background-color: $background-color !important;
  color: $form-inout-color;
}

.ant-select-selector:focus-within {
  background-color: $background-color !important;
}

.spin {
  width: 100%;
  margin: auto;
  padding: 5rem 0;
}

input:-internal-edge-excel-like-autofill-previewed {
  color: $white-color !important;
}

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.ant-input:focus,
.ant-input-focused {
  box-shadow: none;
  outline: 0;
}

.ant-btn-primary {
  box-shadow: none;
}

.ant-btn-default:not(:disabled):hover {
  color: $white-color;
}

.btn-white {
  background-color: $white-color;
  color: $background-color;
  &:hover {
    color: $background-color !important;
    background-color: $white-color !important;
  }
}

.map-dropdown-overlay .ant-dropdown-menu {
  background-color: $background-color;
  max-height: 280px;
  overflow: scroll;
  left: -17px !important;
  top: 10px !important;
}

.map-dropdown-overlay .ant-dropdown-menu .ant-dropdown-menu-item,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title {
  color: $white-color;
  max-width: 275px;
  &:hover {
    background-color: rgba(128, 128, 128, 0.448) !important;
  }
}

.map-dropdown-overlay .ant-dropdown-menu .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-selected,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-submenu
  .ant-dropdown-menu
  .ant-dropdown-menu-submenu-title-selected {
  background-color: rgba(128, 128, 128, 0.448) !important;
}

::-webkit-scrollbar {
  width: 1px;
}

::selection {
  color: $white-color !important;
  background-color: $light-blue !important;
}

* {
  scrollbar-width: none !important;
}

::-webkit-scrollbar-track {
  background: $form-inout-color; /* Color of the scrollbar track */
}

@media screen and (max-width: 930px) {
  .ant-form {
    width: 350px;
  }

  .map-dropdown-overlay .ant-dropdown-menu .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item,
  .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title,
  .ant-dropdown-menu-submenu
    .ant-dropdown-menu
    .ant-dropdown-menu-submenu-title {
    width: 330px;
  }
}

@media screen and (max-width: 800px) {
  .container {
    .image-container {
      display: none;
    }
  }

  .vd {
    display: none;
  }
}

@media screen and (max-width: 575px) {
  .ant-form .ant-form-item .ant-form-item-label,
  .ant-form .ant-form-item .ant-form-item-control {
    flex: none !important;
  }
}

@media screen and (max-width: 430px) {
  .map-dropdown-overlay .ant-dropdown-menu .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item,
  .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title,
  .ant-dropdown-menu-submenu
    .ant-dropdown-menu
    .ant-dropdown-menu-submenu-title {
    width: 269px;
  }
}

@media screen and (max-width: 400px) {
  .ant-form {
    width: 300px;
  }
}
