@import "../../../variables/variables.scss";

.nav-items {
  position: absolute;
  left: 250px;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  padding: 0 0.5rem;

  .nav-item {
    color: $form-inout-color;
    cursor: pointer;

    &:hover {
      color: white;
    }

    &.selected {
      color: white;
    }
  }
}

// .nav-items-vertical {
//   display: none;
// }

@media screen and (max-width: 766px) {
  .nav-items {
    display: none;
  }
  .nav-items-vertical {
    display: flex;
    flex-direction: column;
    left: 0px;
    padding: 0 1.5rem;
    margin: 0;
    width: 300px !important;

    .nav-item {
      color: $form-inout-color;
      cursor: pointer;
      font-size: 16px;
      padding: 10px;

      a {
        display: flex;
        width: 100%;
        &:hover {
          color: white;
          border-radius: 5px;
        }
      }

      &.selected {
        color: white;
        background-color: $light-blue;
        border-radius: 5px;
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .nav-items-vertical {
    width: 280px !important;
  }
}
