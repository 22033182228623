@import "../../variables/variables.scss";

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $background-color;
  height: 100vh;

  .image-container {
    width: calc(100% - 10px);
    display: flex;
    align-items: center;
    justify-content: center;
    .cover-image {
      max-width: 100%;
      height: auto;
      width: 70%;
    }
  }

  .content-container {
    width: calc(100% - 10px);
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .button-container {
      width: 300px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      & > *:nth-child(2),
      & > *:nth-child(3) {
        margin-top: 10px;
      }
    }
  }
}

.ant-btn {
  height: auto;
  padding: 14px 12px !important;
  width: 100%;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font-family;
}
