@import "../../variables/variables.scss";

.dashboard-container {
  padding: 0 2rem 2rem 2rem;

  .page-heading {
    color: $white-color;
    text-align: center;
  }

  .tasks-goalsection {
    display: flex;
    justify-content: space-between;
    .task-list,
    .goal-container {
      width: calc(100% - 42px);
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .task-list {
      .task-type-heading {
        color: $white-color;
        font-weight: 600;
        border-radius: 16px;
        width: 80%;
        max-width: 400px;
        background-color: $background-color;
        margin-bottom: 1rem;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: $form-input-border;

        span {
          font-size: 14px;
        }
      }

      .assignmentDetails {
        width: 80%;
        max-width: 400px;
      }
    }

    .v-divider {
      border-inline-start: none;
      height: 70vh;
      width: 3.5px;
    }

    .h-divider {
      display: none;
    }
  }
}

@media screen and (max-width: 930px) {
  .dashboard-container {
    .tasks-goalsection {
      .task-list {
        .task-type-heading,
        .assignmentDetails {
          width: 90%;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .dashboard-container {
    .tasks-goalsection {
      flex-direction: column-reverse;
      align-items: center;
      .task-list {
        width: 95%;
        .task-type-heading,
        .assignmentDetails {
          width: 100%;
        }
      }

      .h-divider {
        display: block;
        max-width: 400px;
        width: 95%;
        border-block-start: none;
        height: 2.5px;
      }

      .v-divider {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .dashboard-container {
    .task-list {
      width: 100%;
    }
  }
}
