@import "../../variables/variables.scss";

.ant-result {
  display: flex;
  flex-direction: column;
  align-items: center;

  .ant-result-title,
  .ant-result-subtitle {
    color: white;
  }
}
