@import "../../variables/variables.scss";

.crewlist-container {
  .crew-card {
    background-color: $background-color;
    border: $form-input-border;
    border-radius: 16px;
    width: 80%;
    max-width: 400px;
    margin: auto;

    .ant-card-body {
      display: flex;
      flex-direction: column;
      color: $white-color;
      padding: 0 24px 24px 24px;

      .crew:not(:last-child) {
        margin-bottom: 1rem;
      }

      .add-user {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        cursor: pointer;

        span {
          margin-left: 11px;
        }
      }

      .crew {
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        .crew-detail {
          display: flex;
          .name-icons {
            display: flex;
            flex-direction: column;
            .name {
              margin-left: 10px;
              text-transform: capitalize;
            }
          }
        }

        .captain {
          font-size: 10px;
          border-radius: 13px;
          border: 1px solid $light-blue;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          padding: 1px 14px;
          height: 17px;
        }
      }

      .no-icons {
        .crew-detail {
          .name-icons {
            justify-content: center;
            .name {
              margin-top: 5px;
            }
          }
        }

        .captain {
          margin-top: 5px;
        }
      }

      .crew {
        .ant-dropdown-trigger {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
      }

      .no-icons {
        .ant-dropdown-trigger {
          .crew-detail {
            .name-icons {
              .profile-details-small {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.dropdown {
  min-width: 200px !important;
  .ant-dropdown-arrow:before {
    background-color: $nav-backgound-color;
  }
  .ant-dropdown-menu {
    background-color: $nav-backgound-color;
    font-family: $font-family;

    .ant-dropdown-menu-item {
      color: $white-color;

      &:hover {
        background-color: #80808029 !important;
      }
    }
  }
}

.ant-card-head {
  border-bottom: none !important;
}

.ant-card-head-title {
  color: $white-color;
  font-family: $font-family;
}

.ant-card-extra {
  font-size: 25px !important;
}

.ant-modal {
  .ant-modal-content {
    background-color: $nav-backgound-color;

    .ant-modal-body {
      .ant-modal-confirm-body-wrapper {
        display: flex;
        flex-direction: column;

        .ant-modal-confirm-body {
          .ant-modal-confirm-title,
          .ant-modal-confirm-content {
            color: $white-color;
          }
          margin-bottom: 1rem;
        }

        .ant-modal-confirm-btns {
          margin-top: 0;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .ant-btn {
            width: auto;
            height: 0;
          }

          .ant-btn-text {
            color: $text-grey;

            &:nth-child(2) {
              color: $light-blue;
            }

            &:hover {
              background: none;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 930px) {
  .crewlist-container {
    .crew-card {
      width: 90%;
    }
  }
}

@media screen and (max-width: 450px) {
  .crewlist-container {
    .crew-card {
      width: 100%;
    }
  }
}
