@import "../../../variables/variables.scss";

.usernav-container {
  height: 80px;
  background-color: $nav-backgound-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 24px 24px;
  position: sticky;
  top: 0;
  z-index: 10;

  .logo-container {
    margin: 0;

    a {
      .logo {
        width: 120px;
      }
    }
  }

  .menu-items {
    display: flex;
    align-items: center;
  }

  .nav-icons {
    width: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // .nav-avatar {
    //   background-color: $background-color;
    //   border-width: 2px;
    // }

    .anticon {
      color: white;

      svg {
        width: 1.2rem;
        height: 1.2rem;
      }
    }

    .display {
      display: none;
    }
  }
}

.nav-sidebar {
  font-family: $font-family;
  background-color: $nav-backgound-color !important;
  color: $form-inout-color;
}

.nav-sidebar .ant-drawer-header-title {
  justify-content: flex-end;
}

.anticon-arrow-left {
  svg {
    color: $text-grey !important;
  }
}

.profile-dropdown {
  right: 0px !important;
  top: 85px !important;
  // width: 312px;
  left: auto !important;

  .ant-dropdown-menu,
  .ant-dropdown-menu-submenu .ant-dropdown-menu {
    background-color: $nav-backgound-color;
    font-family: $font-family;
  }
}

.nav-sidebar {
  .ant-drawer-wrapper-body {
    .ant-drawer-header {
      padding: 16px;
      .ant-drawer-header-title {
        .ant-drawer-close {
          margin-inline-end: 0;
        }
      }
    }
    .ant-drawer-body {
      .ant-menu-light.ant-menu-root.ant-menu-inline,
      .ant-menu-light.ant-menu-root.ant-menu-vertical {
        border-inline-end: none;
      }
    }
  }
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-submenu
  .ant-dropdown-menu
  .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-active,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-submenu
  .ant-dropdown-menu
  .ant-dropdown-menu-submenu-title-active {
  background: none !important;
}

@media only screen and (max-width: 767px) {
  .usernav-container {
    .menu-items {
      .nav-items {
        display: none;
      }
    }

    .nav-icons {
      width: 120px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .display {
        display: inline-block;
      }
    }
  }

  .ant-menu-item-selected {
    color: $white-color !important;
  }
}

@media only screen and (max-width: 400px) {
  .ant-drawer-left > .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}
