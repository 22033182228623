@import "../../variables/variables.scss";

.crew-result {
  width: 100%;
  .divider-container {
    max-width: 400px;
  }
  .result-card {
    max-width: 400px;
    background-color: $background-color;
    border: $form-input-border;
    border-radius: 16px;
    margin-bottom: 1rem;

    .ant-card-body {
      padding: 0.7rem 1rem;
      display: flex;
      flex-direction: row;
      // align-items: center;

      &::before,
      &::after {
        display: none;
      }

      .avatar {
        margin-right: 0.5rem;
        // margin-top: 6px;
      }

      .content {
        display: flex;
        flex-direction: column;
        width: calc(100% - 47px);

        .name-section {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          .invite-button {
            width: 100px;
            padding: 5px 10px !important;
            font-size: 13px;
            height: 25px;
            margin-top: 5px;
          }

          .crew-name {
            color: $white-color;
            margin: 0;
          }

          .crew-name {
            font-weight: 600;
            text-transform: capitalize;
          }
        }

        .button-section {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;

          .profile-details-small {
            .ant-collapse > .ant-collapse-item > .ant-collapse-header {
              padding: 0 8px 6px 0;
            }
            .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
              padding: 0 8px 6px 0;
            }
            .section {
              width: 175px;
            }
          }
          .hub {
            display: flex;
            align-items: center;
            margin-top: 1.2rem;
            img {
              margin-right: 5px;
            }
            .hub-name {
              color: $white-color;
              text-transform: capitalize;
              font-size: 12px;
              font-weight: 600;
            }
            .hub-name-blue {
              color: $light-blue;
              font-size: 12px;
              font-weight: 600;
            }
          }

          .center {
            margin-top: 0;
          }
        }
      }

      .no-icons {
        .name-section {
          .crew-name {
            margin-top: 10px !important;
          }
        }

        .button-section {
          justify-content: flex-end;
          .hub {
            margin-top: 13px !important;
          }
          .profile-details-small {
            display: none;
          }
        }
      }

      .no-icons-button {
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
}

.ant-divider-horizontal {
  min-width: 0;
}
