// primary color
$light-blue: #22aade;

$divider-color: #4e4e61;

// background colors
$background-color: #1e1e1e;
$nav-backgound-color: #353542;

// text colors
$white-color: #ffffff;
$text-grey: #a3a1a1;

// other colors
$red: #b10f2e;
$orange: #ffa699;
$green: #307351;

$dark-grey: #292f36;
$form-input-background: #1c1c23;
$light-grey: #444c55;
$form-inout-color: #666680;
$add-btn: #38b3e1;
$btn-grey: rgba(255, 255, 255, 0.1);
$delete-btn: rgba(177, 15, 46, 0.5);
$edit-btn: rgba(37, 171, 222, 0.5);
$done-btn: rgba(48, 115, 81, 0.5);
$grey-background-color: rgba(78, 78, 97, 0.2);

$form-input-border: 1px solid #353542;
$add-item-border: 2px dashed #353542;

$font-family: "Source Sans Pro", sans-serif;
