@import "../../../variables/variables.scss";

.hubPreview-container {
  padding: 1rem 2rem 2rem 2rem;
  background: $light-blue;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  .preview-page-content {
    text-align: center;
    color: $white-color;
    margin-block-start: 0;
    .hubProfile-container {
      padding: 0 !important;
    }
  }
}

.profile-from-container {
  .stage-title {
    text-align: center;
    font-size: 12px;
  }
  .edit-user-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 300px;
    margin: 0 3rem;
    .form-item {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }

    .h1 {
      min-height: 330px;
    }

    .h2 {
      min-height: 445px;
    }

    .height-adjusted {
      height: auto;
    }
    .input-heading {
      margin-block-start: 0;
      margin-block-end: 0;
      color: $white-color;
      text-align: center;
    }

    .ant-form-item {
      width: 100%;
    }

    .profile-section {
      display: flex;
      justify-content: center;

      .avatar-container {
        position: relative;
        cursor: pointer;

        .profile-edit-btn {
          height: 15px;
          width: 15px;
          position: absolute;
          bottom: 4px;
          left: 45.5px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $light-blue;
          border-radius: 2px;
        }
      }
    }

    .custom-slider {
      .ant-slider-mark-text {
        margin-top: 5px;
      }
    }

    .submit-button {
      width: 300px;
      padding: 14px 12px !important;
    }
    .form-buttons {
      display: flex;
      width: 300px;
      justify-content: space-between;
      button {
        width: 140px;
        padding: 10px 12px !important;
      }
      & > :first-child {
        margin-right: 10px;
      }
      .ant-btn-default:not(:disabled):not(.ant-btn-disabled):active {
        color: $white-color;
      }
    }

    .clear-btn {
      background: transparent;
      color: $light-blue;
      font-size: 12px;
      padding: 14px 12px 0 12px !important;
      &:hover {
        background: transparent;
        color: $light-blue;
      }
    }

    .completed-page {
      align-items: center;
      h1 {
        color: $white-color;
      }
      .completed-page-content {
        text-align: center;
        width: 300px;
      }
    }
  }

  .progress-bars {
    display: flex;
    .ant-progress.ant-progress-status-success .ant-progress-bg {
      background-color: $light-blue;
    }
  }

  .progress-bars > *:not(:last-child) {
    margin-right: 5px;
  }
}

.radio-button-group {
  width: 100%;

  .radio-button-container {
    width: 100%;
    display: flex;

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: $white-color;
      border-color: $light-blue;
    }

    .ant-radio-button-wrapper:hover {
      color: $white-color;
      border-color: $light-blue;
    }

    .radio-button {
      width: 100%;
      border: $form-input-border;
      border-radius: 16px;
      padding: 1rem 0;
      height: auto;
      line-height: 28px;
      span {
        display: block;
        width: 100%;
        text-align: center;
      }
    }

    .adjusted-border {
      border-radius: 16px 0 0 16px !important;
    }

    .button-big {
      max-width: 300px;
    }

    .rent-status-button {
      text-transform: capitalize;
      background-color: transparent;
      border-radius: 0 16px 16px 0;
      padding: 0.8rem 0 !important;
      font-weight: 400;
      width: 125px;
      line-height: 1.2;
      white-space: initial;
      height: 61.6px;
    }

    .included-border {
      border: 1px solid $light-blue;
      &:hover,
      &:active {
        background-color: transparent;
        border: 1px solid $light-blue;
      }
    }

    .no-border {
      border: $form-input-border;
      &:hover,
      &:active {
        background-color: transparent;
        border: $form-input-border;
      }
    }
  }
}

.radio-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  .radio-button-container:not(:last-child) {
    margin-bottom: 10px;
  }
}

.radio-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  // grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  .button-small {
    width: 145px;
  }

  .additional-class {
    padding: 0.8rem 0;
    span:last-of-type {
      line-height: 1;
    }
  }
}

.radio-super-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  // grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  .add-class {
    padding: 0.8rem 0;
    span:last-of-type {
      line-height: 1.2;
    }
  }
}

.checkbox-group {
  width: 100%;
  .radio-button-container {
    width: 100%;
    display: flex;
    .ant-checkbox-wrapper {
      height: 61.6px;
      color: $white-color;
      border: $form-input-border;
      border-radius: 16px;
      padding: 1rem 0;
      width: 100%;
      &:hover {
        border-color: $light-blue;
      }
      .ant-checkbox {
        display: none;
      }

      span:last-of-type {
        width: 100%;
        text-align: center;
      }
    }
    .ant-checkbox-wrapper-checked {
      border-color: $light-blue;
    }

    .add-class {
      padding: 0;
      span:last-of-type {
        line-height: 1;
        margin-top: 12px;
      }
    }

    .adjusted-border {
      border-radius: 16px 0 0 16px !important;
    }

    .rent-status-button {
      text-transform: capitalize;
      background-color: transparent;
      border-radius: 0 16px 16px 0;
      padding: 0.8rem 0 !important;
      font-weight: 400;
      width: 125px;
      line-height: 1.2;
      white-space: initial;
      height: 61.6px;
    }

    .included-border {
      border: 1px solid $light-blue;
      &:hover,
      &:active {
        background-color: transparent;
        border: 1px solid $light-blue;
      }
    }

    .no-border {
      border: $form-input-border;
      &:hover,
      &:active {
        background-color: transparent;
        border: $form-input-border;
      }
    }
  }
}

.checkbox-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  .radio-button-container:not(:last-child) {
    margin-bottom: 10px;
  }
  .ant-checkbox-wrapper {
    width: 100%;
  }
}

.checkbox-super-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.input-textArea {
  width: 100%;
  max-width: 273px !important;
  margin: auto;
  .ant-input-data-count {
    color: $light-blue;
    font-size: 12px;
  }
}

.input-location {
  margin-bottom: 0;
  margin-top: -14px;
  .ant-input-affix-wrapper {
    padding: 11px 16px;
    .ant-input-suffix {
      .ant-spin {
        color: $text-grey;
      }
    }
  }
}

.custom-slider {
  .ant-slider-mark-text {
    color: $white-color;
  }
  .ant-slider-mark-text-active {
    color: $white-color;
  }

  .ant-slider-handle::after {
    box-shadow: 0 0 0 5px $light-blue;
  }
}

.hubImage-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  .ant-upload-select {
    width: 100%;
    margin-bottom: 10px;
    .upload-btn {
      background-color: transparent;
      border: $form-input-border;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 16px;
      .anticon-upload {
        margin-bottom: 5px;
        svg {
          height: 20px;
          width: 20px;
        }
      }
      .upload,
      .upload-condition {
        color: $text-grey;
      }

      .upload {
        margin-inline-start: 0;
      }

      .upload-condition {
        font-size: 12px;
      }

      &:hover {
        background-color: transparent;
      }
      &:active {
        background-color: transparent;
        border: $form-input-border;
      }
    }
  }
  .ant-upload-wrapper .ant-upload-list::before,
  .ant-upload-wrapper .ant-upload-list::after {
    display: none;
  }
  .ant-upload-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    // grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 5px;
    .ant-upload-list-item-container {
      max-width: 142.5px;

      .ant-upload-list-item {
        border: $form-input-border;

        .anticon-delete {
          color: $white-color !important;
        }
      }
    }
  }
}

.add-input-btn {
  width: 100% !important;
  padding: 11px 16px;
  border: 1px solid $light-blue;
  background-color: transparent;
  border-radius: 16px;

  .ant-btn-icon .anticon {
    font-size: 10px;
    svg {
      fill: $light-blue;
    }
  }

  &:hover,
  &:active {
    border: 1px solid $light-blue !important;
    background-color: transparent !important;
  }
}

.removable-item {
  display: flex;
  align-items: center;
  .additional {
    min-width: 300px;
  }
  .anticon-minus-circle {
    margin-top: -25px;
    margin-left: 5px;
    font-size: 15px;

    svg {
      fill: $text-grey;
    }
  }
  .ant-form-item-has-error {
    // margin-bottom: 2px !important;
    .ant-form-item-explain-error {
      padding: 0 10px 0px 10px !important;
    }
  }
}

// .place-input {
// margin-bottom: 10px;
// }

.form-map {
  border-radius: 16px;
  margin-top: 10px;
}

@media screen and (max-width: 930px) {
  .profile-from-container {
    .edit-user-form {
      margin: 0 2rem;
    }
  }
}

@media screen and (max-width: 430px) {
  .profile-from-container {
    .edit-user-form {
      width: 280px;
      margin: 0;

      .h1 {
        min-height: 350px;
      }

      .h2 {
        min-height: 380px;
      }

      .height-adjusted {
        height: auto;
      }

      .form-buttons {
        width: 280px;
      }
    }
  }

  .removable-item {
    .additional {
      min-width: 280px;
    }
  }

  .radio-grid {
    .button-small {
      width: 135px;
    }
  }

  .hubImage-upload {
    .ant-upload-list {
      .ant-upload-list-item-container {
        width: 135px;
      }
    }
  }
}
