@import "../../variables/variables.scss";

.userProfile-container {
  width: 100%;

  .signout-btn {
    background: transparent;
    color: $text-grey;
    font-size: 14px;
    padding: 0 !important;
    box-shadow: none;
    justify-content: flex-end;

    span {
      text-align: end;
    }
  }

  .profile-section {
    display: flex;
    align-items: center;

    .name-email {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      .user-name {
        text-transform: capitalize;
        color: $white-color;
        font-size: 1.2rem;
        font-weight: 700;
      }

      .user-email {
        font-size: 14px;
        font-weight: 500;
        color: $text-grey;
      }
    }
  }
}
