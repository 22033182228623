@import "../../variables/variables.scss";

.hubManagement-container {
  padding: 3rem 2rem;
  .crew-assignment {
    display: flex;
    .crew-side {
      width: calc(100% - 42px);
      display: flex;
      flex-direction: column;
      .hub-name {
        color: $white-color;
        text-align: center;
        text-transform: capitalize;
        margin-top: 0;
        margin-block-end: 0.5rem;
      }

      .hub-view-btn {
        text-align: center;
        margin-top: 0;
        font-size: 14px;
        cursor: pointer;
        color: $light-blue;
      }
      .hub-profile-btn {
        width: 160px;
        padding: 8px 12px !important;
        margin: 0 auto 1.2rem auto;
      }
    }
    .h-divider {
      display: none;
    }
  }
}

@media screen and (max-width: 930px) {
  .hubManagement-container {
    padding: 3rem 1rem;
  }
}

@media screen and (max-width: 660px) {
  .hubManagement-container {
    .crew-assignment {
      flex-direction: column;
      align-items: center;
      .h-divider {
        display: block;
        max-width: 400px;
        border-block-start: 0;
      }

      .v-divider {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .hubManagement-container {
    padding: 1rem 0;
  }
}
