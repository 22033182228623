@import "../../variables/variables.scss";

.crewProfile-container {
  display: flex;
  padding: 3rem 1rem;
  max-width: 900px;
  margin: auto;
  justify-content: space-between;
  .no-data-class {
    min-width: 400px;
    .ant-empty-image {
      margin-bottom: 1rem;
    }
    .ant-empty-description {
      color: $white-color;
      margin-bottom: 1rem;
    }
  }
  .profile-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    .avatar-container {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      .ant-avatar {
        margin-top: -77px;
      }
    }
    .name,
    .level {
      color: $white-color;
    }

    .name {
      text-align: center;
      text-transform: capitalize;
    }

    .level {
      font-size: 1.25rem;
      margin-bottom: 20px;
    }

    .item-container {
      display: flex;
      flex-direction: column;
      width: 500px;
      margin-bottom: 24px;
      .title {
        font-size: 1.25rem;
        margin-bottom: 10px;
        font-weight: 350;
        color: $white-color;
      }
      .description {
        font-size: 12px;
        color: $white-color;
      }

      .item {
        width: 100%;
        display: flex;
        padding: 7px 0;
        border-bottom: 1px solid $nav-backgound-color;
        .label {
          color: $text-grey;
          margin-top: 3px;
          min-width: 170px;
          font-size: 14px;
        }
        .value-container {
          display: flex;
          justify-content: space-between;
          width: 100%;
          .value {
            color: $white-color;
            margin-top: 3px;
            font-size: 14px;
          }
          img {
            width: 25px;
          }
        }
      }
    }
  }

  .hub-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    .hub-name {
      text-transform: capitalize;
      color: $white-color;
      margin: 1rem 0;
    }

    .hub-location {
      color: $text-grey;
      margin-bottom: 1rem;
    }
    .crewProfile-map {
      border-radius: 10px;
    }
  }
}

@media screen and (max-width: 850px) {
  .crewProfile-container {
    padding: 3rem 1rem;
    justify-content: space-evenly;
    .profile-details {
      .item-container {
        width: 400px;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .crewProfile-container {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 450px) {
  .crewProfile-container {
    align-items: center;
    .profile-details {
      .name {
        font-size: 28px;
      }
      .item-container {
        width: 350px;
        .item {
          .label {
            min-width: 130px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .crewProfile-container {
    align-items: center;
    .profile-details {
      .name {
        font-size: 28px;
      }
      .item-container {
        width: 330px;
        .item {
          .label {
            min-width: 130px;
          }
        }
      }
    }
  }
}
