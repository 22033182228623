@import "../../variables/variables.scss";

.container {
  .vd {
    height: 80%;
    width: 2.5px;
  }
  .forgot {
    height: 70%;
    justify-content: space-between;

    .forgot-form {
      .form-title {
        font-style: normal;
        font-weight: 700;
        color: $white-color;
        text-align: center;
      }
    }

    .success-page {
      .content {
        color: $white-color;
        font-size: 14px;
        text-align: center;
      }
    }
  }
}
