@import "../../variables/variables.scss";

.assignmentListMenu-container {
  width: calc(100% - 42px);
  display: flex;
  flex-direction: column;
  align-items: center;

  .assignment-list {
    width: 80%;
    max-width: 400px;
    .add-assignment {
      background-color: $background-color;
      border: $add-item-border;
      border-radius: 16px;
      margin-bottom: 0.5rem;
      display: flex;
      justify-content: center;
      cursor: pointer;

      .ant-card-body {
        width: 200px;
        padding: 0.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        color: $light-blue;

        p {
          font-weight: 600;
        }
      }
    }

    .buttons-conatiner {
      display: flex;
      justify-content: space-between;
      .load-btn {
        background: transparent;
      }

      .next-btn {
        color: $light-blue;
      }

      .prev-btn {
        color: $text-grey;
      }
    }
  }

  .assignmentList-menu {
    width: 80%;
    max-width: 400px;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    background-color: $background-color;
    border-radius: 16px;
    padding: 0.5rem 0rem;
    color: $white-color;
    border: $form-input-border;

    .ant-tabs-nav {
      width: 100%;
      font-family: $font-family;

      .ant-tabs-nav-wrap {
        width: 100%;

        .ant-tabs-nav-list {
          padding: 0 2rem;
          width: 100%;
          align-items: center;
          justify-content: space-between;

          .ant-tabs-tab {
            width: 50%;
            text-align: center;
            padding: 4px 0;

            .ant-tabs-tab-btn {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $white-color;
    text-shadow: 0 0 0.25px currentcolor;
  }

  .ant-tabs .ant-tabs-ink-bar {
    position: absolute;
    border-radius: 16px;
    background-color: $nav-backgound-color;
    pointer-events: none;
    z-index: -1;
  }

  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 30px;
  }

  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0;
  }

  .ant-tabs-nav-operations {
    display: none !important;
  }

  .ant-tabs .ant-tabs-tab:hover {
    color: $white-color;
  }

  .ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible),
  .ant-tabs .ant-tabs-tab-remove:focus:not(:focus-visible),
  .ant-tabs .ant-tabs-tab-btn:active,
  .ant-tabs .ant-tabs-tab-remove:active {
    color: $white-color;
  }

  .ant-menu-light.ant-menu-horizontal .ant-menu-item-selected::after {
    display: none;
  }

  .ant-menu-light.ant-menu-horizontal > .ant-menu-item::after {
    display: none;
  }
}
.ant-menu::before {
  display: none !important;
}

.ant-menu::after {
  display: none !important;
}

.ant-menu-horizontal::after {
  display: none !important;
}

.assignment-drawer {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center;
  .ant-drawer-header {
    display: flex;
    padding: 12px 24px 24px 24px;
    .ant-drawer-header-title {
      order: 2;
      display: flex;
      .ant-drawer-title {
        text-align: center;
        color: $white-color;
        font-size: 2rem;
        font-weight: 700;
        margin-left: 30px;
        margin-right: 0;

        order: 1;
      }

      .ant-drawer-close {
        order: 2;
        margin-inline-end: 0;
        width: auto;

        .cancel-btn {
          padding: 5px 10px !important;
          &:hover {
            background-color: $light-blue;
          }
        }
      }

      .ant-drawer-extra {
        order: 1;
      }
    }
  }
  .ant-drawer-body {
    display: flex !important;
    justify-content: center !important;
    padding: 0;
  }
}

.anticon-arrow-right,
.anticon-arrow-left {
  color: $text-grey;
  svg {
    height: 20px;
    width: 20px;
  }
}

.root-assignment-drawer {
  .ant-drawer-content-wrapper {
    right: -45%;
  }
}

@media screen and (max-width: 930px) {
  .assignmentListMenu-container {
    .assignmentList-menu {
      width: 90%;
    }

    .assignment-list {
      width: 90%;
    }
  }
}

@media screen and (max-width: 835px) {
  .root-assignment-drawer {
    .ant-drawer-content-wrapper {
      right: -47%;
    }
  }

  .assignment-drawer {
    .ant-drawer-header {
      padding: 16px 12px;
      .ant-drawer-header-title {
        .ant-drawer-title {
          font-size: 1.75rem;
        }
      }
    }
  }
}

@media screen and (max-height: 765px) {
  .assignment-drawer {
    .ant-drawer-wrapper-body {
      height: 100%;
      .ant-drawer-header {
        padding: 16px 24px;
      }
    }
  }
}

@media screen and (max-width: 740px) {
  .root-assignment-drawer {
    .ant-drawer-content-wrapper {
      right: -54%;
    }
  }
  .assignment-drawer {
    .ant-drawer-header {
      .ant-drawer-header-title {
        .ant-drawer-title {
          font-size: 1.75rem;
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .root-assignment-drawer {
    .ant-drawer-content-wrapper {
      right: -68%;
    }
  }
}

@media screen and (max-width: 520px) {
  .root-assignment-drawer {
    .ant-drawer-content-wrapper {
      right: -93%;
    }
  }
}

@media screen and (max-width: 450px) {
  .assignmentListMenu-container {
    .assignmentList-menu {
      width: 100%;
      padding: 0.5rem 1rem;

      .ant-tabs-nav {
        .ant-tabs-nav-wrap {
          .ant-tabs-nav-list {
            .ant-tabs-tab {
              width: 90px;
              font-size: 12px;
            }
          }
        }
      }
    }

    .assignment-list {
      width: 100%;
    }
  }

  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 26px !important;
  }

  .assignment-drawer {
    .ant-drawer-wrapper-body {
      .ant-drawer-header {
        padding: 16px 4px;
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .assignment-drawer {
    .ant-drawer-wrapper-body {
      .ant-drawer-header {
        .ant-drawer-header-title {
          .ant-drawer-title {
            font-size: 1.65rem;
          }
        }
      }
    }
  }
}
