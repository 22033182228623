@import "../../variables/variables.scss";

.hub-card {
  min-width: 300px;
  background-color: $background-color;
  border: $form-input-border;
  border-radius: 16px;
  margin-bottom: 1rem;
  .ant-card-body {
    padding: 1rem !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    &::before,
    &::after {
      display: none;
    }
    .details {
      display: flex;

      img {
        margin-right: 1rem;
      }

      .content {
        display: flex;
        flex-direction: column;

        .hub-name {
          color: $white-color;
          margin: 0 0 5px 0;
          text-transform: capitalize;
        }
      }
    }
    .preview-btn {
      padding: 5px 12px !important;
      width: 95px;
      font-size: 13px;

      &:hover {
        color: $white-color;
      }
    }
  }
}

.ant-avatar-group .ant-avatar {
  border: none;
}

.marginSide {
  margin: 0 12px;
}

.marginSide:first-child {
  margin: 0 12px 0 0;
}

.ant-popover-inner-content > .marginSide {
  margin: 0 2px 0 0;
}
