@import "../../variables/variables.scss";

.assignmenDetails-container {
  width: 100%;
  .assignment-emptyCard {
    background-color: $background-color;
    border: $form-input-border;
    border-radius: 16px;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: center;
    cursor: pointer;

    .ant-card-body {
      padding: 0.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      color: $background-color;
    }
  }
}
