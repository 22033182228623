@import "../../variables/variables.scss";

.assignmentCard-container {
  display: flex;
  margin-bottom: 0.5rem;
  overflow: hidden;
  cursor: pointer;

  .assignment-card {
    background-color: $background-color;
    border: $form-input-border;
    border-radius: 16px;
    width: 100%;
    transition: all 0.3s ease-in-out;

    &.compressed {
      width: calc(100% - 126px);
      transition: all 0.3s ease-in-out;
    }

    .ant-card-body {
      padding: 0.8rem 1.5rem 0 1.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      color: $white-color;
      height: 85px;

      .left-section {
        display: flex;

        img {
          margin-right: 10px;
        }
        .name-point {
          display: flex;
          flex-direction: column;

          .name {
            margin: 0;
            font-weight: 600;
            font-size: 1rem;
            text-transform: capitalize;
          }

          .point-section {
            display: flex;
            align-items: center;

            .star {
              margin-right: 5px;
              svg {
                color: yellow;
                width: 12px;
                height: 12px;
              }
            }

            .point {
              margin: 0;
            }
          }
        }
      }

      .right-section {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        &.hide {
          display: none;
        }

        .time {
          margin: 0;
        }

        .date {
          margin: 9px 0 2px 0;
        }

        .assigned_to {
          .ant-avatar-group {
            .ant-avatar-circle {
              background-color: transparent;
              color: $text-grey;
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }

      &::before,
      &::after {
        display: none;
      }
    }

    .ant-card-actions {
      background-color: transparent;
      border: none;
      padding: 0 1.5rem;
      margin-top: -6px;
      flex-direction: column;
      li {
        margin: 0;
        width: 100% !important;

        .ant-progress .ant-progress-inner {
          background-color: $nav-backgound-color;
          .ant-progress-bg {
            background-color: $light-blue;
            height: 3px !important;
          }
        }

        span {
          .more-details {
            font-family: $font-family;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: 5px;

            span {
              color: $text-grey;
              font-size: 11px;
              text-decoration: underline;
            }
          }
        }
      }

      &::before,
      &::after {
        display: none;
      }
    }
  }

  .buttons {
    display: flex;
    transition: all 0.3s ease-in-out;
    justify-content: space-between;
    width: 0;
    .button {
      border-radius: 12px;
      width: 40px !important;
    }

    .blue {
      background-color: $light-blue;
      &:hover {
        background-color: $light-blue;
      }
    }

    .red {
      background-color: $red;
      &:hover {
        background-color: $red;
      }
    }

    .green {
      background-color: $green;
      &:hover {
        background-color: $green;
      }
    }
  }

  .displayNone {
    display: none !important;
  }

  .displayhidden {
    visibility: hidden !important;
  }

  .show {
    display: flex;
    transition: all 0.3s ease-in-out;
    width: 126px;
  }
}

.ant-card .ant-card-actions > li:not(:last-child) {
  border-inline-end: none;
}

.ant-progress-line {
  margin-bottom: 0;
}

// .ant-drawer-right > .ant-drawer-mask {
//   background: none;
// }
.ant-drawer-right > .ant-drawer-content-wrapper {
  top: 84px;
  width: 51% !important;

  .drawer-right {
    background-color: $background-color !important;

    .ant-drawer-wrapper-body {
      background-color: $background-color !important;

      .ant-drawer-header {
        border: none;

        .ant-drawer-header-title {
          justify-content: flex-start;
        }
      }
    }
  }
}

.complete-confirmation-content {
  display: flex;
  align-items: center;
  .assignment-detail {
    display: flex;
    flex-direction: column;
    .name {
      text-transform: capitalize;
      font-weight: 600;
    }
    .date {
      font-size: 11px;
      font-weight: 400;
      margin-bottom: 5px;
    }
  }
}

.confirm-btn {
  span {
    color: $text-grey;
    font-size: 11px;
    font-weight: 400;
  }
}

.edit-assignment-drawer {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center;
  .ant-drawer-header {
    padding: 12px 24px 24px 24px;
    .ant-drawer-header-title {
      .ant-drawer-title {
        text-align: center;
        color: $white-color;
        font-size: 2rem;
        font-weight: 700;
        margin-right: 40px;
      }
    }
  }
  .ant-drawer-body {
    display: flex !important;
    justify-content: center !important;
    padding: 0;
  }
}

.ant-popover .ant-popover-inner {
  background-color: $background-color;
}
.ant-popover .ant-popover-arrow:before {
  background: $background-color;
}

@media screen and (max-height: 765px) {
  .edit-assignment-drawer {
    .ant-drawer-wrapper-body {
      height: 100%;
      .ant-drawer-header {
        padding: 16px 24px;
      }
    }
  }
}

@media screen and (max-width: 740px) {
  .ant-drawer-right > .ant-drawer-content-wrapper {
    width: 60% !important;
  }
}

@media screen and (max-width: 650px) {
  .ant-drawer-right > .ant-drawer-content-wrapper {
    width: 75% !important;
  }
}

@media screen and (max-width: 520px) {
  .ant-drawer-right > .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}

@media screen and (max-width: 400px) {
  .edit-assignment-drawer {
    .ant-drawer-header {
      .ant-drawer-header-title {
        .ant-drawer-title {
          font-size: 1.75rem;
          font-weight: 700;
        }
      }
    }
  }
}
