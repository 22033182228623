@import "../../variables/variables.scss";

.notificationIcon-container {
  position: relative;
  display: flex;
  align-items: center;
  .notification-count {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8.5px;
    font-weight: 500 !important;
    left: 12px;
    top: -10px;
    color: #fff;
    width: fit-content;
    min-width: 16.5px;
    max-width: 18px;
    height: 15.85px;
    background-color: rgb(241, 9, 9);
    position: absolute;
    border-radius: 50%;
    user-select: none !important;
    cursor: pointer;
  }
}

.ant-notification-notice .ant-notification-notice-close {
  color: $form-inout-color;
  left: 20px;
}

.root-notification-drawer {
  .ant-drawer-content-wrapper {
    width: 350px !important;
    height: 200px;
    .notification-drawer {
      height: 100%;
    }
    .ant-drawer-body {
      padding: 0 24px 24px 24px;
      overflow: auto;
      height: 100%;
    }
  }
}

@media screen and (max-width: 400px) {
  .custom-class {
    min-width: 98vw;
  }
}
