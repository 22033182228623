@import "../../variables/variables.scss";

.editProfile-container {
  padding: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.editProfile-container > .ant-card {
  border-radius: 16px;
  background: transparent !important;
  border: $form-input-border;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-card-body {
    padding: 3rem;
  }
}

.preview-page-container > .ant-card {
  border: none;
  background: transparent !important;
}

.preview-page-container > .ant-card > .ant-card-body {
  padding: 0;
}

@media screen and (max-width: 930px) {
  .editProfile-container {
    .editUser-card {
      .ant-card-body {
        padding: 3rem 1.5rem;
      }
    }
  }
}

@media screen and (max-width: 430px) {
  .editProfile-container {
    .editUser-card {
      .ant-card-body {
        padding: 3rem 2rem;
      }
    }
  }
}
