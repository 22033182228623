@import "../../variables/variables.scss";

.add-crew {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .searchbar-container {
    margin-bottom: 1.5rem;
    max-width: 400px;
    width: 100%;
    .search-bar {
      max-width: 400px;
      width: 100%;

      .ant-input-group-addon {
        height: 45.6px;

        .ant-input-search-button {
          height: 100%;
          border: $form-input-border;
          color: $text-grey;
          background-color: $background-color;
          border-top-right-radius: 16px;
          border-bottom-right-radius: 16px;

          .anticon-search {
            color: $text-grey;
          }
        }
      }
    }
  }
  .search-result {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .add-crew {
    .searchbar-container {
      margin-bottom: 1.5rem;
      max-width: 400px;
      width: 100%;
      .search-bar {
        max-width: 400px;
        width: 100%;
      }
    }
    .search-result {
      width: 100%;
    }
  }
}
