@import "../../../variables/variables.scss";

.feedback-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;
  width: 100%;

  .feedback-title {
    font-size: 14px;
    font-weight: 600;
    color: $white-color;
    margin-bottom: 1.5rem;
  }

  .success-container {
    .success-title {
      color: $text-grey;
      font-size: 14px;
      font-weight: 600;
    }

    .fd-btn {
      background: transparent;
      color: $light-blue;
      font-size: 12px;
      padding: 0 !important;
      &:hover {
        color: $light-blue;
      }
    }
  }

  .feedback-form {
    .form-input-box {
      background-color: $background-color;
      border: $form-input-border;
      border-radius: 3px;
      margin-bottom: 1.5rem;

      .ant-form-item-label {
        border-bottom: $form-input-border;
        padding: 5px 10px;
        background-color: $background-color;
      }

      .ant-form-item .ant-form-item-label > label {
        width: 100%;
        font-weight: 600;
        color: $white-color;
        font-family: $font-family;
      }

      .ant-input {
        background-color: $background-color;
        padding: 10px;
        border: none;
        font-family: $font-family;
        color: $white-color;
      }
    }

    .feedback-submit-btn {
      padding: 11px 0 !important;
    }
  }
}

.ant-form-item .ant-form-item-explain-error {
  padding: 0 10px 5px 10px;
  font-family: $font-family;
}

@media screen and (max-width: 760px) {
  .feedback-form {
    width: 280px !important;
  }
}

@media screen and (max-width: 625px) {
  .feedback-form {
    width: 100% !important;
  }
}
