@import "../../variables/variables.scss";

.landing-page {
  font-family: "Russo One", serif;
  .landing-nav {
    background-color: $background-color;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;

    width: 100%;
    z-index: 1000;
    .logo {
      width: 140px;
      margin-left: -10px;
    }
    .nav-btn {
      color: $white-color;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
  .landing-content {
    padding-top: 80px;
    background-color: $background-color;
    .image-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;
      width: 100%;

      .image-item {
        position: relative;
        min-width: 50%;

        img {
          width: 100%;
          height: auto;
          display: block;
        }

        .caption {
          color: $white-color;
          text-transform: uppercase;
          font-size: 16px;
          text-align: center;
        }
      }

      .blue-box {
        background-color: $light-blue;
      }
      .black-box {
        background-color: $background-color;
      }
      .blank {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 0 10px 10px 0;
      }
    }

    .bg-image {
      padding: 1rem;
      background: url("../../assets/images/couple_img.webp") no-repeat center
        center;
      background-size: cover;
      height: 700px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      .text {
        color: $white-color;
        font-size: 25px;
      }

      .download-btn {
        padding: 0 !important;
        width: min-content;
        font-family: "Russo One", serif;
        font-weight: 400;
        color: $light-blue;
      }
    }
  }
}

@media only screen and (min-width: 1600px) {
  .landing-page {
    .landing-content .bg-image {
      min-height: 950px;
    }
  }
}

@media only screen and (max-width: 1600px) {
  .landing-page {
    .landing-content .bg-image {
      height: 950px;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .landing-page {
    .landing-nav .logo {
      margin-right: 30px;
    }
    .landing-content .image-grid .image-item .caption {
      font-size: 22px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .landing-page {
    .landing-content .bg-image {
      height: 850px;
    }
  }
}

@media only screen and (max-width: 700px) {
  .landing-page {
    .landing-nav {
      padding: 0 30px;
      .logo {
        margin-left: -10px;
      }
    }
    .landing-content .image-grid .image-item .caption {
      font-size: 14px;
    }

    .landing-content .bg-image {
      height: 500px;
    }
  }
}

@media only screen and (max-width: 450px) {
  .landing-page {
    .landing-nav {
      padding: 0 20px;
      .logo {
        width: 120px;
      }
      .nav-btn {
        font-size: 12px;
      }
    }
    .landing-content .image-grid .image-item .caption {
      font-size: 12px;
    }
  }
}

@media only screen and (max-width: 370px) {
  .landing-page {
    .landing-nav {
      padding: 0 15px;
      .nav-btn {
        font-size: 11px;
      }
    }
  }
}
