@import "../../variables/variables.scss";

.inviteRequest-card {
  width: 380px;
  background-color: $background-color;
  border: $form-input-border;
  border-radius: 16px;
  margin-bottom: 1rem;

  .ant-card-body {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-family: "Source Sans Pro", sans-serif;

    &::before,
    &::after {
      display: none;
    }

    .content-container {
      width: 75%;
      .content {
        color: white;
        margin: 0;
        text-align: end;
      }

      .confirm-buttons {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;

        .confirm-button {
          width: 75px;
          padding: 5px 10px !important;
          font-size: 13px;
          &:first-child {
            margin-right: 5px;
          }
        }

        .cancel-button {
          width: 100px;
          padding: 5px 10px !important;
          font-size: 13px;
        }
      }
    }
  }
}
