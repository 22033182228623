@import "../../variables/variables.scss";

.addon-style {
  margin: 0 !important;
  box-shadow: 0px 0px 43px 11px rgb(151 190 206 / 75%);
}

.hubProfile-container {
  margin: 2rem 0 0 0;
  background-color: $background-color;

  .no-image-container {
    .ant-empty-description {
      color: $white-color;
    }
  }

  .photos-crew {
    padding-top: 1rem;
    max-width: 1000px;
    display: flex;
    justify-content: space-between;
    margin: auto;
    .photos,
    .crew {
      cursor: pointer;
      display: flex;
      align-items: center;
      span {
        color: $text-grey;
        font-size: 13px;
      }
    }

    .photos {
      img {
        margin-right: 1rem;
      }
    }

    .crew {
      img {
        margin-left: 1rem;
      }
    }
  }

  .hub-view {
    display: flex;
    max-width: 1000px;
    justify-content: space-between;
    margin: auto;
    .white {
      color: $white-color;
    }
    .hub-details {
      max-width: 600px;
      .name-address-container {
        display: flex;
        justify-content: space-between;

        .name-address {
          .name {
            color: $white-color;
            text-transform: capitalize;
            margin: 0;
          }
          p {
            margin-block-start: 0.5rem;
            font-size: 13px;
          }
        }
        .map {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          span {
            margin-top: 5px;
            font-size: 13px;
            color: $text-grey;
            text-decoration: underline;
          }
        }
      }
      .item-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
        .title {
          font-size: 1.5rem;
          margin-bottom: 10px;
          font-weight: 400;
          color: $white-color;
        }
        .description {
          font-size: 12px;
        }
        .load-btn {
          width: fit-content;
          padding: 14px 0px !important;
          font-size: 12px;
          font-weight: 400;
          color: $light-blue;
          &:hover,
          &:active {
            background: none;
          }
        }
        .item {
          width: 100%;
          display: flex;
          padding: 14px 0;
          border-bottom: 1px solid $nav-backgound-color;
          .label {
            color: $text-grey;
            min-width: 140px;
          }
          .value-container {
            display: flex;
            justify-content: space-between;
            width: 100%;
            .value {
              min-width: 150px;
              max-width: 250px;
              color: $white-color;
            }

            .status-red {
              color: $red;
            }

            .red {
              color: $red;
            }
          }
        }
      }
    }
    .hub-captain {
      margin-left: 2rem;
    }
  }

  .ant-descriptions .ant-descriptions-title {
    color: $white-color;
    text-align: center;
    font-size: 20px;
  }

  .hub-description .ant-descriptions-item-content {
    color: $white-color;
    text-transform: capitalize;
  }

  .hub-description.ant-descriptions-bordered
    > .ant-descriptions-view
    .ant-descriptions-row
    > .ant-descriptions-item-label {
    color: $white-color;
    border-inline-end: $form-input-border;
    text-transform: capitalize;
    font-weight: 600;
  }

  .hub-description.ant-descriptions-bordered > .ant-descriptions-view {
    border: $form-input-border;
  }

  .hub-description.ant-descriptions-bordered
    > .ant-descriptions-view
    .ant-descriptions-row
    > .ant-descriptions-item-label,
  .hub-description.ant-descriptions-bordered
    > .ant-descriptions-view
    .ant-descriptions-row
    > .ant-descriptions-item-content {
    border-inline-end: $form-input-border;
  }

  .hub-description.ant-descriptions-bordered
    > .ant-descriptions-view
    .ant-descriptions-row {
    border-bottom: $form-input-border;
  }
}

.map-modal {
  .ant-modal-close {
    .ant-modal-close-x {
      .ant-modal-close-icon {
        color: $text-grey;
      }
    }
  }
  .ant-modal-body {
    .leaflet-container {
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: 800px) {
  .hubProfile-container {
    .hub-view {
      flex-direction: column;
      align-items: center;

      .hub-captain {
        margin-left: 0;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .hubProfile-container
    .hub-view
    .hub-details
    .item-container
    .item
    .value-container
    .value {
    min-width: 90px;
  }

  .hubProfile-container .hub-view .hub-details .item-container .item .label {
    min-width: 129px;
  }
}

@media screen and (min-width: 1300px) {
  .hubProfile-container .hub-view {
    max-width: 1300px;
  }

  .hubProfile-container
    .hub-view
    .hub-details
    .item-container
    .item
    .value-container
    .value {
    min-width: 200px;
  }

  .hubProfile-container .hub-view .hub-details .item-container .item .label {
    min-width: 240px;
  }

  .hubProfile-container .photos-crew {
    max-width: 1300px;
  }
}
