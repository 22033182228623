@import "../../../variables/variables.scss";

.notification-item {
  background-color: $background-color;
  border: $form-input-border;
  border-radius: 16px;
  margin-bottom: 0.5rem;

  .ant-card-body {
    padding: 0.3rem 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "Source Sans Pro", sans-serif;

    &::before,
    &::after {
      display: none;
    }

    .notification-content {
      color: white;
      margin-left: 2rem;
    }
  }
}
