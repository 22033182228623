@import "../../variables/variables.scss";

.delete-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .delete-content {
    width: 280px;
    text-align: center;
    color: $white-color;
  }

  .delete-confirmation {
    color: $white-color;
  }
  .caution-btn {
    width: 280px;
    background-color: $red;
    &:hover {
      background-color: $red !important;
    }

    &:active {
      background: $red !important;
      color: $white-color !important;
    }
  }
  .form-content {
    color: $white-color;
    text-align: center;
  }
  .deleteData-btn {
    background: transparent !important;
    border: 1px solid $red;
    &:hover {
      border-color: $red !important;
    }
    &:active {
      border-color: $red !important;
      color: $white-color !important;
    }
  }
  .back-btn {
    background: transparent !important;
    color: $text-grey;
    &:hover {
      color: $text-grey;
    }
  }
}
.delete-user-confirm {
  color: $red !important;
}
