@import "../../variables/variables.scss";

.user-card {
  background-color: $background-color;
  border: $form-input-border;
  border-radius: 16px;
  width: 100%;
  min-width: 300px;

  .ant-card-body {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $white-color;
    h1 {
      margin-block-start: 0.5rem;
      margin-block-end: 0.5rem;
      text-align: center;
    }
    p {
      margin: 0;
    }
    .userCard-btn {
      font-weight: 400;
      margin: 10px;
      font-size: 16px;
    }

    .userCard-btn:last-child {
      margin: 0;
    }
  }
}
