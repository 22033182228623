@import "../../variables/variables.scss";

.goal-and-taskStatusDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  .goal-section {
    display: flex;
    flex-direction: column;
    align-items: center;

    .dotted1,
    .dotted2,
    .dotted3 {
      border-left: 4.08px dotted $white-color;
      border-bottom: 4.08px dotted $white-color;
      border-radius: 150px;
      transform: rotate(137deg);
    }

    .dotted1 {
      width: 305px;
      height: 305px;
      opacity: 0.02;
      position: relative;
      top: -12px;
    }
    .dotted2 {
      width: 270px;
      height: 270px;
      opacity: 0.05;
      position: absolute;
      top: 168px;
    }
    .dotted3 {
      width: 235px;
      height: 235px;
      opacity: 0.03;
      position: absolute;
      top: 184px;
    }
    .progress-container {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 200px;

      .level-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 40px;

        .level {
          margin-bottom: 0;
          font-size: 30px;
          font-weight: 700;
          color: $white-color;
          line-height: 40px;
        }

        .level-name {
          margin-top: 10px;
          font-size: 12px;
          font-weight: 600;
          line-height: 16px;
          color: $white-color;
        }
      }

      .points-progress {
        width: 133px;
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 182px;
        .start-point,
        .end-point {
          color: $white-color;
          font-size: 12px;
          font-weight: 600;
        }
      }
      .ant-progress-circle {
        .ant-progress-inner {
          width: 200px !important;
          height: 200px !important;
        }
      }
    }
  }
}

.ant-progress-circle-path {
  stroke: $light-blue !important;
  transition: stroke-dashoffset 0.6s ease 0s, stroke-dasharray 0.6s ease 0s,
    stroke 0.6s ease 0s, stroke-width 0.06s ease 0.3s, opacity 0.6s ease 0s !important;
}

.ant-progress .ant-progress-circle-trail {
  stroke: $nav-backgound-color;
}

@media screen and (max-width: 450px) {
  .goal-and-taskStatusDetails {
    .goal-section {
      margin-top: 0;

      .dotted1 {
        width: 240px;
        height: 240px;
        position: absolute;
        top: 152px;
      }
      .dotted2 {
        width: 210px;
        height: 210px;
      }
      .dotted3 {
        width: 175px;
        height: 175px;
      }
      .progress-container {
        .level-container {
          top: 27px;

          .level {
            font-size: 25px;
          }

          .level-name {
            margin-top: 8px;
          }
        }

        .points-progress {
          width: 106px;
          top: 137px;
        }
        .ant-progress-circle {
          .ant-progress-inner {
            width: 150px !important;
            height: 150px !important;
          }
        }
      }
    }
  }
}
