@import "../../variables/variables.scss";

.crew-list-dropdown {
  min-width: 340px !important;
  .ant-dropdown-menu,
  .ant-dropdown-menu-submenu .ant-dropdown-menu {
    background-color: $background-color;
    .ant-dropdown-menu-item {
      .ant-dropdown-menu-title-content {
        .crew {
          display: flex;
          justify-content: space-between;
          cursor: pointer;

          .crew-detail {
            display: flex;
            .name-icons {
              .name {
                margin-left: 10px;
                color: $white-color;
                text-transform: capitalize;
              }
            }
          }

          .captain {
            font-size: 10px;
            border-radius: 13px;
            border: 1px solid $light-blue;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            padding: 1px 14px;
            color: $white-color;
            height: 17px;
          }
        }

        .no-icons {
          .crew-detail {
            align-items: center;
            .name-icons {
              justify-content: center;
              .name {
                margin-top: 5px;
              }
              .profile-details-small {
                display: none;
              }
            }
          }

          .captain {
            margin-top: 5px;
          }
        }

        .crew-actions {
          display: flex;
          margin-top: 10px;
          max-height: 0; // Start with hidden
          opacity: 0;
          overflow: hidden;
          transition: max-height 0.4s ease, opacity 0.4s ease;
          .crew-btn {
            border-radius: 0;
            padding: 6px 12px !important;
            font-weight: 400;
          }
        }

        .show {
          max-height: 50px; // Adjust this value to be higher than the content height
          opacity: 1;
        }

        .hide {
          max-height: 0;
          opacity: 0;
        }
      }
    }
  }
}
