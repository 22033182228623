@import "../../../variables/variables.scss";

.assignment-form {
  .form-input-box {
    background-color: $background-color;
    border: $form-input-border;
    border-radius: 3px;
    margin-bottom: 10px;

    .ant-form-item {
      margin-bottom: 0;
    }

    .switch-container {
      .ant-row {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .ant-form-item-label > label {
        color: $text-grey !important;
      }
      .ant-form-item-label {
        border-bottom: 0;
        margin-right: 10px;
        padding: 0 !important;
      }

      .ant-form-item-control {
        width: 40px;
        .ant-form-item-control-input {
          min-height: 0;
        }
      }
    }

    .ant-form-item-label {
      border-bottom: $form-input-border;
      padding: 5px 10px !important;
      background-color: $nav-backgound-color;
    }

    .ant-form-item .ant-form-item-label > label {
      width: 100%;
      font-weight: 600;
      color: $white-color;
      &::after {
        display: none !important;
      }
    }

    .ant-input {
      background-color: $background-color;
      padding: 10px;
      border: none;
      color: $white-color;
    }

    .ant-input-number:target .ant-input-number-handler-wrap,
    .ant-input-number:hover .ant-input-number-handler-wrap,
    .ant-input-number:visited .ant-input-number-handler-wrap,
    .ant-input-number:active .ant-input-number-handler-wrap,
    .ant-input-number:focus .ant-input-number-handler-wrap,
    .ant-input-number-focused .ant-input-number-handler-wrap {
      opacity: 10;
    }

    .ant-picker {
      width: 100%;
      background-color: transparent;
      border: none;
      padding: 10px;

      .ant-picker-input {
        input {
          color: $white-color;
          font-family: $font-family;
        }
      }
    }

    .ant-input:focus,
    .ant-input-focused {
      box-shadow: none;
    }

    .ant-select-focused .ant-select-selector {
      box-shadow: none !important;
    }

    .ant-select-open {
      background-color: $background-color !important;
    }

    .ant-select-selector {
      border: none !important;
      background-color: $background-color !important;
      padding: 5px 10px !important;
      height: 42px !important;
      margin-bottom: -5px;
    }

    .ant-select-arrow {
      position: absolute;
      top: -16px;
    }

    .ant-input-status-error:not(.ant-input-disabled):not(
        .ant-input-borderless
      ).ant-input:focus,
    .ant-input-status-error:not(.ant-input-disabled):not(
        .ant-input-borderless
      ).ant-input-focused {
      box-shadow: none !important;
    }

    .ant-form-item .ant-form-item-explain-error {
      padding: 0 10px 5px 10px;
      font-family: $font-family;
    }
  }
}

.point-slider {
  margin: 15px 18px;
  .ant-slider-rail {
    background-color: #4e4e61;
  }
  &:hover {
    .ant-slider-rail {
      background-color: #4e4e61;
    }
  }
}

.point-slider {
  .ant-slider-track {
    background-color: $light-blue;
  }
  &:hover {
    .ant-slider-track {
      background-color: $light-blue;
    }
  }
}

.point-slider .ant-slider-handle:hover::after,
.point-slider .ant-slider-handle:active::after,
.point-slider .ant-slider-handle:focus::after {
  box-shadow: 0 0 0 5px $light-blue;
}

.ant-picker:not(
    .ant-picker-disabled
  ).ant-picker-status-error.ant-picker-focused,
.ant-picker:not(.ant-picker-disabled).ant-picker-status-error:focus {
  border: none;
  box-shadow: none;
}

.ant-picker-focused {
  box-shadow: none;
}

.ant-picker:not(.ant-picker-disabled).ant-picker-status-error,
.ant-picker:not(.ant-picker-disabled).ant-picker-status-error:not(
    [disabled]
  ):hover {
  background-color: transparent;
  border: none;
}

.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: $light-blue;
}

.ant-switch.ant-switch-checked {
  background: $light-blue;
}

.ant-input-group .ant-input-group-addon {
  border: none;
}

.emoji-group {
  padding: 5px 10px;
  display: flex;
  .ant-radio-button-wrapper:first-child,
  .ant-radio-button-wrapper:last-child,
  .ant-radio-button-wrapper {
    border-start-end-radius: 0;
    border-end-end-radius: 0;
    border-start-start-radius: 0;
    border-end-start-radius: 0;
    margin-right: 5px;
    background-color: $nav-backgound-color;
    border: $form-input-border;
    border-radius: 3px;
    padding: 0px 6px;
  }

  .add-emoji {
    background-color: $nav-backgound-color;
    border: $form-input-border;
    border-radius: 3px;
    padding: 0px 6px !important;
    width: 32px;
  }
}

.epr-main {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}

.epr_-4ueg5a,
.epr_-8623pq {
  background-color: $background-color !important;
}

.epr_-to8mm {
  border-color: #353542 !important;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}

.epr_-2zpaw9 {
  border: $form-input-border !important;
}

.epr_-99lr9q {
  background: none !important;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: $light-blue;
  border-color: $light-blue;
}

.ant-switch {
  background-color: $text-grey;
}

.ant-switch:hover:not(.ant-switch-disabled) {
  background-color: $text-grey;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover {
  background: $light-blue;
  border-color: $light-blue;
}

.ant-select-selection-item-content {
  text-transform: capitalize;
}

.ant-tag span:nth-child(2) {
  font-family: $font-family;
}

.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: $light-blue;
}

.ant-select-dropdown .ant-select-item-option {
  margin-bottom: 5px;
}

.ant-select-dropdown .ant-select-item-option:hover {
  background-color: $light-blue !important;
}

@media screen and (max-width: 500px) {
  .input-number .ant-input-number-handler-wrap,
  .ant-input-number:target .ant-input-number-handler-wrap,
  .ant-input-number:hover .ant-input-number-handler-wrap,
  .ant-input-number:visited .ant-input-number-handler-wrap,
  .ant-input-number:active .ant-input-number-handler-wrap,
  .ant-input-number:focus .ant-input-number-handler-wrap,
  .ant-input-number-focused .ant-input-number-handler-wrap {
    opacity: 10;
  }
}

.crew-dropdown {
  overflow: hidden;
  .ant-select-selector {
    .ant-select-selection-overflow {
      flex-wrap: nowrap;
      overflow: scroll;
    }
  }
}
.crew-member,
.crew-tag {
  display: flex;
  align-items: center;
  background-color: transparent;
}

.crew-member {
  .ant-dropdown-trigger {
    width: 100%;
  }
}
