@import "../../variables/variables";

.inviteRequestViewer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .inviteRequests-container {
    .no-content-card {
      width: 280px;
      background-color: $background-color;
      border: $form-input-border;
      border-radius: 16px;
      margin-bottom: 1rem;
      .ant-card-body {
        padding: 2rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        color: $form-inout-color;
      }
    }
  }
}
