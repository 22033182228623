@import "../../variables/variables.scss";

.profile-details-container {
  .ant-collapse > .ant-collapse-item-active > .ant-collapse-header {
    display: none;
  }
  .section {
    width: 200px;
    margin: auto;

    .item {
      display: flex;
      align-items: center;
      margin-bottom: 2.5px;

      .icon {
        margin-right: 10px;
      }
      .icon-text {
        font-size: 12px;
        color: $white-color;
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    .pointer {
      cursor: pointer;
    }
    .minus-button {
      position: absolute;
      left: 350px;
      top: 179px;
      min-width: 20px;
      width: 20px;
      height: 20px;
      padding: 0 !important;

      .ant-btn-icon {
        .anticon-minus {
          font-size: 10px;
        }
      }
    }
  }

  .icon-section {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 2.5px;
    width: 200px;
    margin: auto;
    margin-bottom: 10px;

    .icon {
      margin-bottom: 2.5px;
    }
  }
}

.profile-details-small {
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0 8px 6px 8px;
  }

  .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 0 8px 6px 8px;
  }

  .ant-collapse > .ant-collapse-item-active > .ant-collapse-header {
    display: none;
  }

  .section {
    width: 200px;
    margin: auto;

    .item {
      display: flex;
      align-items: center;
      margin-bottom: 2.5px;

      .icon {
        width: 18px;
        height: 18px;
        margin-right: 10px;
      }
      .icon-text {
        font-size: 9px;
        color: $white-color;
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  .icon-section {
    display: grid;
    grid-template-columns: repeat(6, 0fr);
    gap: 2.5px;

    .icon {
      width: 20px;
      height: 20px;
      margin-bottom: 2.5px;
    }
  }
}

@media screen and (max-width: 930px) {
  .profile-details-container {
    .section {
      .minus-button {
        left: 305px;
      }
    }
  }
}

@media screen and (max-width: 430px) {
  .profile-details-container {
    .section {
      .minus-button {
        left: 260px;
      }
    }
  }
}
