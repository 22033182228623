@import "../../variables/variables.scss";

.weekday-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;

  .weekday-selector {
    display: flex;
    justify-content: center;

    .weekday-button {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: $form-input-border;
      margin: 0 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $background-color;
      cursor: pointer;
      font-weight: 600;
      color: white;
      font-family: $font-family;
      transition: all 0.3s ease-in-out;

      &.selected {
        transition: all 0.3s ease-in-out;
        background-color: $light-blue;
        color: white;
        border: none;
      }
    }
  }

  .selected-days-text {
    margin-block-end: 0 !important;
    text-align: center;
  }
}

@media screen and (max-width: 400px) {
  .weekday-container {
    .weekday-selector {
      .weekday-button {
        width: 33px;
        height: 33px;
      }
    }
  }
}
